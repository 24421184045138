import React from 'react';
import {Component} from 'react';

class Home extends Component {
    render() {
        return (
            <div className=''>
                <div className='landing'>
                    <div className='row vh-center justify-content-center'>
                        <div className='all-center'>
                            <h1 className=' display-3  col-xs-6 welcome' style={{color: 'White'}}>Welcome</h1>
                            <div className="row justify-content-center align-middle">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <p className='lead' style={{color: 'white'}}>Hello Nicole, Travis, and other wedding attendees! First off a huge congratulations to Travis and Nicole. They have been excellent friends and it is great to see them get married! To celebrate, here is some music. For those that I have not met, my name is Alec and I am a software engineer and a hobbyist music producer. I have created this site as my contribution to the art display for the wedding. Linked below are a few Google Drive folders with some music projects that I have worked on. In the folder labeled Squirrel Plane you will find a few demos featuring Travis on vocals. Before the pandemic, Travis, my father, and I had a cover band. At one point it was tentatively named Squirrel Plane, hence the name of this site. We were able to record ourselves playing Someday by The Strokes as well as Evil by Interpol. I have mixed and tweaked these recordings to improve the sound quality. In the second folder you will find some of my original music. Please enjoy the music and once again a huge congrats to Travis & Nicole!!</p>
                                    <a type="button" className="btn btn-lg btn-dark text-light" href={"https://drive.google.com/drive/folders/1GD12Vue3ODgIZCBidPZg0zVSQbPaxE7U?usp=sharing"} style={{color:'black'}}>Music via Google Drive</a>
                                </div>
                            </div>
                            {/*<div className={"row justify-content-center"}>*/}
                            {/*    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 last-row">*/}
                            {/*        */}
                            {/*    </div>*/}
                            {/*</div>*/}
                             {/*<div className={"row justify-content-center s"}>*/}
                             {/*    <div className="col-xs-12 col-sm-6 col-lg-4">*/}
                             {/*    </div>*/}
                             {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;