import logo from './logo.svg';
import './components/assets/css/App.css';

//Impprt Router
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import React from 'react';

// CSS Imports
import './components/assets/css/App.css';

//Component Imports
import Header from './components/header/header';
import Home from "./components/pages/home/home";
// import Footer from './components/footer/footer';

function App() {
  return (
      <Router>
        <div className="App">
          <Header />
          <Route exact path='/' component={Home} />
          {/*<Footer />*/}
        </div>
      </Router>
  );
}

export default App;
