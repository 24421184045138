import React, { Component } from 'react';

import {
    Link
} from 'react-router-dom';

class Header extends Component {
    render() {
        return (
            <header>
                <nav className="navbar mr-auto navbar-dark bg-dark justify-content-between">
                    <div className="container">
                        <Link to='/' className='logo-text'>Squirrel Plane</Link>
                        <ul className='nav ml-auto'>
                            <li className="nav-item">
                                <a href={'https://drive.google.com/drive/folders/1GD12Vue3ODgIZCBidPZg0zVSQbPaxE7U?usp=sharing'} className="nav-link">Music via Google Drive</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;
